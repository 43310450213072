import { DateTime } from 'luxon'
import { Assessment } from '../../types/Assessment'
import { CleanDataSettings } from '../../types/CleanDataSettings'
import { cleanDataSettingsToRequestFormat } from '../dataTransformers/formDataTransforms'
import { formatWeatherData } from '../dataTransformers/weatherTransform'

export const cleanWeatherData = async (
  assessmentId: number,
  station: Required<Assessment>['station'],
  data: CleanDataSettings,
  pollingFrequencySeconds: number = 1
): Promise<ReturnType<typeof formatWeatherData>> => {
  return new Promise(async (resolve, reject) => {
    const startYear = DateTime.fromMillis(data.startDate).year
    const endYear = DateTime.fromMillis(data.endDate).year
    const url = `/api/weather?station_ID=${station.id}&start_year=${startYear}&end_year=${endYear}&assessment_ID=${assessmentId}`
    const res = await fetchWithToken(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        station,
        cleanDataSettings: cleanDataSettingsToRequestFormat(data),
      }),
    })
    if (res.status === 200) {
      resolve(formatWeatherData(await res.json()))
    }

    if (res.status === 202) {
      const json = await res.json()
      const { statusQueryGetUri } = json
      console.log('statusQueryGetUri is', statusQueryGetUri)

      const checkStatus = async () => {
        const res = await fetch(statusQueryGetUri)
        if (!res.ok) {
          reject(res)
          return
        }
        const json = await res.json()
        const { runtimeStatus } = json
        if (runtimeStatus === 'Completed') {
          const cleanedDataResponse = await fetch(json.output)
          if (cleanedDataResponse.ok) {
            resolve(formatWeatherData(await cleanedDataResponse.json()))
          } else {
            reject(cleanedDataResponse)
          }
          return
        }
        if (runtimeStatus === 'Failed') {
          reject(json)
          return
        }
        setTimeout(checkStatus, pollingFrequencySeconds * 1000)
      }
      checkStatus()
    } else {
      reject(res)
    }
  })
}
